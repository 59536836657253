<template>
    <div>
        <b-card class="question-header-card">
            <section id="question-header">
                <div class="question-header">
                    <div>
                        <b-button @click="backClicked"><b-icon icon="chevron-left"></b-icon></b-button>
                    </div>
                    <div>
                        {{ this.newCurrentQuestion.id }}
                    </div>
                    <div>
                        <div>
                            <span :style="{ fontWeight: 'bold' }" v-html="this.newCurrentQuestion.inquiry"></span>
                            <span class="hint-multicheckbox" v-if="this.newCurrentQuestion.answer.type === 'multicheckbox'">
                                (Mehrfachnennung möglich)</span>
                        </div>
                    </div>
                    <div class="clickable" style="display: flex; justify-content: flex-end;">
                        <b-icon scale="1.5" :icon="starred ? 'bookmark-fill' : 'bookmark'"
                            @click="toggleIsStarred"></b-icon>
                    </div>
                </div>
            </section>
        </b-card>
        <b-card class="question-detail">
            <section id="question">
                <div class="question" :style="cssVars">
                    <div v-for="option in newCurrentQuestion.answer.options" :key=newCurrentQuestion.id+option.optionId>
                        <div v-if="newCurrentQuestion.answer.type === 'multicheckbox'">
                            <Checkbox type="question" :question_id="newCurrentQuestion.id" :question="newCurrentQuestion"
                                :option="option" />
                        </div>
                        <div v-else>
                            <Radio :question_id="newCurrentQuestion.id" :question="newCurrentQuestion" :option="option" />
                        </div>
                    </div>
                </div>
            </section>
        </b-card>

        <b-card style="border: none">
            <div class="button-footer">
                <div style="display: flex; justify-content: right">
                    <div v-if="newCurrentQuestion.id > 1">
                        <b-button class="question-nav-button" @click="clickNext('prev')">
                            <feather-icon icon="ChevronsLeftIcon" />&ensp;Vorherige Frage
                        </b-button>
                    </div>
                </div>
                <div>
                    <div v-if="newCurrentQuestion.id < 37">
                        <b-button class="question-nav-button" @click="clickNext('next')">
                            Nächste Frage&ensp;<feather-icon icon="ChevronsRightIcon" />
                        </b-button>
                    </div>

                </div>
            </div>
        </b-card>
        <Modal id="popUpQuestionInquiry" ref="popUpQuestionInquiry" :footer=true>
            <p slot="title"><b> {{ this.popUpTitle }}</b></p>
            <div slot="body">
                {{ this.popUpText }}
            </div>
        </Modal>
    </div>
</template>
<script>


import Checkbox from "../../../components/Checkbox.vue"
import Radio from "../../../components/Radio.vue"
import Button from "../../../components/Button.vue"
import Modal from "../../../components/Modal.vue"
import FeatherIcon from '../../../components/FeatherIcon.vue'
import SingleQuestion from "./Survey_Question.vue"

export default {
    name: 'survey-question-detail',
    props: {
        question: Object,
        type: String,
    },
    components: {
        Checkbox,
        Radio,
        Button,
        Modal,
        SingleQuestion,
        FeatherIcon,
    },
    computed: {
        cssVars() {
            return {
                '--row-count': this.optionRows,
            }
        },
        starred() {

            return this.newCurrentQuestion.isStarred

        },
        answered() {

            return this.newCurrentQuestion.isAnswered

        },
        getAllQuestionsCount() {
            return this.$store.getters['survey/getAllQuestionsCount']
        },
        getCountQuestionAnswered() {
            return this.$store.getters['survey/getCountQuestionAnswered']
        },
        allQuestionAnswered() {
            return this.getAllQuestionsCount - this.getCountQuestionAnswered
        }
    },
    methods: {

        backClicked() {
            this.isNewQuestion = false
            this.$emit('back-clicked')

        },
        clickNext(action) {
            let payload = { action: action, id: this.newCurrentQuestion.id }
            this.newCurrentQuestion = this.$store.getters['survey/getNextQuestion'](payload)
            this.optionRows = Math.round(this.newCurrentQuestion.answer.options.length / 2)
        },

        toggleIsStarred() {
            let payload = {}
            payload = { questionID: this.newCurrentQuestion.id, value: !this.newCurrentQuestion.isStarred }
            this.$store.dispatch('survey/toggleIsStarred', payload)
        },
        popUpMarking() {

            const collection = document.getElementsByClassName("PopUp");
            this.$nextTick(() => {
                collection.forEach((element) => {
                    element.style.textDecoration = 'underline'
                    element.style.color = '#4BB3D4'
                    element.style.cursor = 'Pointer'
                    element.addEventListener("click", () => {
                        let term = this.$store.getters['survey/getTermById'](element.id)
                        this.popUpTitle = term.term
                        this.popUpText = term.definition
                        this.$bvModal.show('popUpQuestionInquiry')
                    });

                })
            })
            this.optionRows = Math.round(this.newCurrentQuestion.answer.options.length / 2)
        }
    },
    created() {
        if (this.question != {}) {
            this.newCurrentQuestion = this.question
        }
    },
    mounted() {
        if (this.question != {}) {
            this.newCurrentQuestion = this.question
        }
        const collection = document.getElementsByClassName("PopUp");
        this.$nextTick(() => {
            collection.forEach((element) => {
                element.style.textDecoration = 'underline'
                element.style.color = '#4BB3D4'
                element.addEventListener("click", () => {
                    let term = this.$store.getters['survey/getTermById'](element.id)
                    this.popUpTitle = term.term
                    this.popUpText = term.definition
                    this.$bvModal.show('popUpQuestionInquiry')
                });

            })

            this.optionRows = Math.round(this.newCurrentQuestion.answer.options.length / 2)
        });
    },
    watch: {
        newCurrentQuestion(newQuestion, oldQuestion) {
            this.popUpMarking()
        }
    },
    data() {
        return {
            isStarred: false,
            isNewQuestion: false,
            afterNewQuestion: false,
            newCurrentQuestion: Object,
            popUpText: '',
            popUpTitle: '',
            optionRows: 0,
            color: 'red',
        }
    }
}

</script>

<style lang="scss" scoped>

.PopUp {
    text-decoration: underline;
    color: #4BB3D4;
    cursor: pointer;
}

.question-header-card {
    border: none;
    height: 5rem;
    border-bottom: 2px solid lightgrey;
    display: flex;

    section#question-header {
        display: flex;
        flex-direction: column;

        .question-header {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: 5% 2% 85% 5%;
            grid-gap: 10px;
        }
    }

}

.button-footer {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 50% 50%;
}

.question-nav-button {
    display: flex;
    background-color: #ECF0F3;
    border: none;
    color: #485159;
    margin: 0 10px 0 10px;
    width: 210px;
    height: 47px;
    align-items: center;
    justify-content: center;
}

.right-button-profil {
    width: 240px;
}

.question-detail {
    margin-bottom: 1rem;
    width: 100%;
    border: none;
    height: 650px;
}

.hint-multicheckbox {
    color: $elsi-color-schrift-hellgrau;
}

section#question {
    display: flex;
    flex-direction: row;
    justify-content: center;

    // calc: 1rem margin from collapsible
    .question {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 10px;
        grid-template-rows: repeat(var(--row-count), 1fr);
        grid-auto-columns: minmax(0, 1fr);

    }
}
</style>