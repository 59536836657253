<template>
    <b-card class="question-card">
        <section id="question">
            <div class="question clickable" :style="{ fontWeight: this.question.isAnswered ? 'normal' : 'bold' }">
                <div @click="$emit('card-clicked', question)">
                    {{ question.id }}
                </div>
                <div @click="$emit('card-clicked', question)">
                    <span v-html="question.inquiry"></span>
                </div>
                <div class="clickable">
                    <b-icon scale="1.2" :icon="starred ? 'bookmark-fill' : 'bookmark'" @click="toggleIsStarred"></b-icon>
                </div>
                <div>
                    <feather-icon :icon="answered ? 'CheckCircleIcon' : 'CircleIcon'" size="18"/>
                </div>
            </div>
        </section>
    </b-card>
</template>

<script>
import FeatherIcon from '../../../components/FeatherIcon.vue'
export default {
    name: 'survey-question-overviewcard',
    props: {
        question: Object,
    },
    components: {
        FeatherIcon
    },
    computed: {
        starred() {
            return this.question.isStarred
        },
        answered() {
            return this.question.isAnswered
        }
    },
    methods: {
        toggleIsStarred() {
            const payload = { questionID: this.question.id, value: !this.question.isStarred }
            this.$store.dispatch('survey/toggleIsStarred', payload)
        },
    },
    data() {
        return {
            isStarred: false,
            isAnswered: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.question-card {
    margin-bottom: 1rem;
    width: 100%;
}

section#question {
    display: flex;
    flex-direction: column;

    .question {
        display: grid;
        grid-template-columns: 20pt auto 32pt 24pt;
    }

}
</style>