<template>
    <div>
        <b-card no-body  style="border: none">
            <div class="radio-card option-card clickable" id="radioCard" @click="clickOption" style="padding: 20px 20px 20px 20px;">
                <input id="radioBox" type="radio" :checked="isCheckedQuestion" @change="changeQuestion">
                <span id="radioCard" style="margin-left: 10px;margin-right:10px;" v-html="option.name"> </span>
                <div v-if="option.popUp && option.popUp > 0" style="display: flex">
                    <feather-icon icon="HelpCircleIcon" size="16" class="popUp clickable" @mouseover="overPopUp"
                        @click="circleClick" @mouseleave="leavePopUp" style="color:#A8AEB2" />
                    <b-modal size="lg" v-model="showModal" :hide-footer=true>
                        <template #modal-header>
                            {{ popUpTitle }}
                        </template>
                        <template #default>
                            {{ popUpText }}
                        </template>
                    </b-modal>

                </div>
            </div>
        </b-card>
    </div>
</template>


<script>
import Modal from "@/components/Modal.vue"
import FeatherIcon from './FeatherIcon.vue'

export default {
    props: {
        type: String,
        question: Object,
        option: Object,
        question_id: Number,
    },
    components: {
        Modal,
        FeatherIcon
    },
    computed: {
        isCheckedQuestion() {
            let payload = { question_id: this.question_id, option_id: this.option.optionId }
            //return this.value === "1" || this.value === true;
            return this.$store.getters['survey/getQuestionCheck'](payload)
        }
    },
    methods: {
        overPopUp(element) {
            this.isOvercircle = true
            let term = this.$store.getters['survey/getTermById'](this.option.popUp)
            this.popUpTitle = term.term
            this.popUpText = term.definition
        },
        leavePopUp(element) {
            this.isOvercircle = false
        },

        clickOption(e) {
            if (!this.isOvercircle) {
                let newChecked = !this.isCheckedQuestion
                let payload
                payload = { isRadio: true, question_id: this.question_id, question_inquiry: this.question.inquiry, option_id: this.option.optionId, checked: newChecked }
                this.$store.dispatch('survey/setRadioQuestion', payload)
                let params = { isRadio: true, questionId: this.question_id, question_inquiry: this.question.inquiry, optionId: this.option.optionId, name: this.option.name, selected: this.option.selected, points: this.option.points }
                this.$store.commit('survey/UPDATE_ANSWER', params)
            }
        },
        changeQuestion(e) {

            // let payload
            // payload = {isRadio: true, question_id: this.question_id, question_inquiry: this.question.inquiry, option_id: this.option.optionId, checked: e.target.checked ? true : false}
            // this.$store.dispatch('survey/setRadioQuestion', payload)
            // let params = {isRadio: true, questionId: this.question_id, question_inquiry: this.question.inquiry, optionId: this.option.optionId, name:this.option.name, selected: this.option.selected, points: this.option.points}
            // this.$store.commit('survey/UPDATE_ANSWER', params)
        },
        circleClick() {
            this.showModal = !this.showModal;
        }
    },
    mounted() {

    },
    data() {
        return {
            checked: false,
            popUpText: '',
            popUpTitle: '',
            isOvercircle: false,
            showModal: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.radio-card {
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>