<template>
    <div>
        <div>
            <slot name="id"></slot>
        </div>
        <div>
            <div :style="{ fontWeight: 'bold' }">
                <slot name="inquiry">
                </slot>
                <slot name="multicheckbox">
                </slot>
                <slot name="iconStarred">
                </slot>
                <slot name="iconAnswered">
                </slot>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'survey-question',
    props: {

    },
    components: {

    },


    data() {
        return {

        }
    }
}

</script>

<style lang="scss" scoped>
.question-header-card {
    border: none;
    height: 5rem;
    border-bottom: 2px solid lightgrey;
    display: flex;

    section#question-header {
        display: flex;
        flex-direction: column;

        .question-header {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: 5% 2% 85% 2% 2%;
            grid-gap: 10px;
        }
    }

}
</style>