<template>
    <div id="bar">
        <div v-for="index in segments" :key="'1-' + index" class="segment segment_1 colored"></div>
        <span v-for="index in filler_segments()" :key="'0-' + index" class="segment"></span>

    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {
        segments_total: {
            type: Number,
            required: true
        },
        segments: {
            type: Number,
            default: 0
        },
        max_width: String,
        max_height: String,
    },
    methods: {
        filler_segments() {
            return this.segments_total - this.segments
        }
    }
}
</script>

<style lang="scss" scoped>
#bar {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-items: stretch;
    flex: initial;
    align-self: stretch;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: $elsi-color-bar-bg;
    border-radius: 30px;

    .segment {
        flex: 1;
        height: 20px;
    }

    .segment_1 {
        background-color: $elsi-color-blue;
    }

    .colored:first-child {
    }

    .segment:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    // Nach 1 h debuggen die Erkenntnis:
    // .colored:last-child {}
    // … funktioniert prinzipiell nicht.
    .colored:last-of-type {
    }

    .segment:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

}
</style>
