<template>
    <div class="row grow w-100">
        <div class="col-1">
            <NavSidebar></NavSidebar>
        </div>
        <div class="main col-11 h-100 py-3 padding-subpages">
            <NavHeader buttonLabel="Zurück" tutorialName="survey" chevron="true" chevronLeft="true"></NavHeader>

            <HeaderCard :icons="[]" info='true' info_color='red'>
                <template #title>
                    <h4>Fragen zum Erstellen des ELSI-Profils</h4>
                </template>
                <template #info>
                    <p>Beantworten Sie die Fragen, um die Relevanz der ELSI-Kategorien für Ihr Forschungsprojekt zu
                        ermitteln. Denken Sie dabei aus der Perspektive eines Anwendungsszenarios Ihrer zu entwickelnden
                        Technologie.</p>
                </template>

            </HeaderCard>

            <section id="content-header-survey">
                <div class="content-header-bar-survey">
                    <div :class="!clickedCat ? 'visible' : 'notVisible'" style="display: flex; justify-content: center;">
                        Filtern</div>
                    <div>
                        <ProgressBarQuestion :segments="getCountQuestionAnswered" :segments_total="getAllQuestionsCount">
                        </ProgressBarQuestion>
                    </div>
                </div>
                <div class="content-survey">
                    <SortSidebar type="questions" :class="!clickedCat ? 'visible' : 'notVisible'"
                        :functionDisabled="clickedCat"></SortSidebar>
                    <div v-if="!clickedCat">
                        <section id="questions">

                            <div class="questions">
                                <!-- <b-form-input class="search" size="lg" id="input-default" type="search" v-model="searchQuery" placeholder="Suche"></b-form-input> -->
                                <div>
                                    <div v-for="question in questions" :key=question.id>

                                        <SurveyQuestionOverviewCard :question="question"
                                            @card-clicked="questionDetail(question)"></SurveyQuestionOverviewCard>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div v-if="clickedCat">
                        <SurveyQuestionDetail type="fromSurvey" :question="this.currentQuestion"
                            @back-clicked="questionOverview"></SurveyQuestionDetail>
                    </div>
                </div>
                <div style="display: flex;">
                    <div style="position: absolute; right: 40px; margin-top: 20px; padding-bottom: 32px;">
                        <Button v-show="allQuestionAnswered == 0" class="right-button-profil" btnStyle="btn-primary"
                            label="ELSI-Profil ansehen" :chevron="true" :chevronLeft="false" :link=true
                            linkTo="/profile"></Button>
                        <Button v-show="!allQuestionAnswered == 0" class="right-button-profil" btnStyle="btn-secondary"
                            v-b-tooltip.hover title='Bitte alle Fragen beantworten' label="ELSI-Profil ansehen"></Button>
                    </div>
                </div>
            </section>
        </div>
        <b-modal v-if="isOverview === false" v-model="isShowTutorialStart" title='Hinweise' ok-only>
            <Tutorial tutorialName="survey" />
            <template #modal-footer>
                <p>Wir empfehlen, bei Unsicherheit eher die intuitiv angemessene Antwortmöglichkeit anzukreuzen als auf
                    “Noch unklar” auszuweichen, da letzteres die Einordnung weniger eindeutig macht.</p>
            </template>
        </b-modal>
    </div>
</template>
<script>

import NavSidebar from "../../../components/Nav_Sidebar.vue"
import SortSidebar from "../../../components/Sort_Sidebar.vue"
import SurveyQuestionOverviewCard from "./Survey_Question_OverviewCard.vue"
import SurveyQuestionDetail from "./Survey_Question_Detail.vue"
import NavHeader from "../../../components/Nav_Header.vue"
import HeaderCard from "../../../components/Header_Card.vue"
import OverviewCollapse from "../../../components/Overview_Collapse.vue"
import ProgressBarQuestion from "../../../components/ProgressBar_Questions.vue"
import Button from "../../../components/Button.vue"
import Tutorial from '../../Tutorial.vue'

export default {
    name: 'survey',
    props: {
        isOverview: {
            type: Boolean,
            default: false
        }
    },
    components: {
        NavHeader,
        HeaderCard,
        OverviewCollapse,
        NavSidebar,
        SortSidebar,
        SurveyQuestionOverviewCard,
        SurveyQuestionDetail,
        ProgressBarQuestion,
        Button,
        Tutorial
    },

    computed: {
        showTutorialAtStart() {
            return this.$store.getters['survey/showTutorialSurvey']
        },
        getCountQuestionAnswered() {
            return this.$store.getters['survey/getCountQuestionAnswered']
        },
        getCountQuestionUnanswered() {
            return this.$store.getters['survey/getCountQuestionUnanswered']
        },

        getAllQuestionsCount() {
            return this.$store.getters['survey/getAllQuestionsCount']
        },
        allQuestionAnswered() {
            return this.getAllQuestionsCount - this.getCountQuestionAnswered
        },
        searchQuery: {
            get() {
                return this.$store.state.questionSearchQuery
            },
            set(val) {
                this.$store.dispatch('survey/setQuestionSearchQuery', val)
            }
        },
        questions() {
            return this.$store.getters['survey/getAllQuestions']
        },
    },
    methods: {

        questionDetail(question) {
            this.clickedCat = true
            this.currentQuestion = question;
        },
        questionOverview(buttonLabel) {
            this.clickedCat = false;
            this.currentQuestion = '';
        },

    },
    created() {

        this.isShowTutorialStart = this.showTutorialAtStart

        if (this.isShowTutorialStart === true) {
            this.$store.commit('survey/SET_TUTORIAL_SURVEY')
        }

    },
    data() {
        return {
            description: "",
            info: "Beantworten Sie die Fragen, um die Relevanz der ELSI-Kategorien für Ihr Forschungsprojekt zu ermitteln. Denken Sie dabei aus der Perspektive eines Anwendungsszenarios Ihrer zu entwickelnden Technologie.",
            clickedCat: false,
            currentQuestion: {},
            isShowTutorialStart: true,
        }
    }
}

</script>

<style lang="scss" scoped>
.notVisible {
    opacity: 0.5;
}

.visible {
    visibility: visible;
}


section#questions {
    display: flex;
    flex-direction: column;


    .questions {
        display: grid;
        grid-template-columns: auto;
        grid-column-gap: 1.5rem;
    }

    .question-panel {
        overflow-y: scroll;
    }
}
</style>